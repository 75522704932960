<template>
  <section id="allegati">
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-card
        title="Allegati"
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-form-file
                ref="file-input"
                v-model="newfiles"
                class="mb-2"
                multiple
              />
            </b-col>
            <b-col md="12">
              <b-list-group>
                <b-list-group-item
                  v-for="(file, idx) in newfiles"
                  :key="idx"
                  class="d-flex"
                >
                  <span class="mr-1">
                    <feather-icon
                      icon="ImageIcon"
                      size="16"
                    />
                  </span>
                  <span>{{ file.name }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-col>
            <b-col
              v-if="newfiles.length>0"
              md="12"
            >
              <div class="demo-inline-spacing">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="text-center"
                  @click="uploadImages()"
                >
                  Carica
                </b-button>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="secondary"
                  class="text-center"
                  @click="newfiles = []"
                >
                  Annulla
                </b-button>
              </div>
            </b-col>
          </b-row>

        </b-card-body>
        <b-card-body>
          <b-list-group>
            <b-list-group-item
              v-for="(file, idx) in files"
              :key="idx"
              class="d-flex justify-content-between align-items-center"
            >
              <span
                class="mr-1"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="16"
                  @click="DownloadFile(file.fullpath)"
                />
              </span>
              <span @click="DownloadFile(file.fullpath)">{{ file.name }}</span>
              <span class="">
                <feather-icon
                  icon="TrashIcon"
                  size="16"
                  @click="DeleteFile(file.fullpath)"
                />
              </span>
            </b-list-group-item>
          </b-list-group>
        </b-card-body>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import Vue from 'vue'
import { VueAutosuggest } from 'vue-autosuggest'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BAvatar, BModal, BButton, VBModal, BTable, BCardBody, BCardFooter, BOverlay, BFormFile, BCard, BListGroup,
  BListGroupItem, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { getUserData } from '@/auth/utils'
import {
  ref, onUnmounted, computed, watch,
} from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import storeModule from '../../../store/app/baseStoreModule'

export default {
  components: {
    BCardActions,
    BFormFile,
    BButton,
    BListGroup,
    BListGroupItem,
    BModal,
    BCard,
    BCardBody,
    BTable,
    VueAutosuggest,
    BCol,
    BRow,
    BAvatar,
    BCardFooter,
    BOverlay,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    onlyList: {
      type: [String, Boolean],
      default: true,
    },
    linkedObject: {
      type: String,
      required: true,
    },
    linkedId: {
      type: [String, Number],
      required: true,
    },
  },
  setup(props, { emit }) {
    const files = ref([])
    const newfiles = ref([])
    const show = ref(false)

    const APP_STORE_MODULE_NAME = 'app-base'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })
    const userData = getUserData()
    const fetchFiles = () => {
      console.log("Festch file")
      show.value = true
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchFileList`, { linkedObject: props.linkedObject, linkedId: props.linkedId })
        .then(res => {
          files.value = res.data.files
        })
        .catch(error => {
        }).finally(show.value = false)
    }

    const DeleteFile = fullpath => {
      Vue.prototype.$swal({
        title: `Eliminare il file?`,
        text: "Attenzione non puoi annullare l'operazione!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, elimina!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          store.dispatch(`${APP_STORE_MODULE_NAME}/deleteFile`, { fullpath })
            .then(res => {
              fetchFiles()
            })
            .catch(error => {
              console.log(error)
            })
        }
      })
    }
    const DownloadFile = fullpath => {
      store.dispatch(`${APP_STORE_MODULE_NAME}/fetchFile`, { fullpath })
        .then(res => {
          if (res.data.Data) {
            const binaryString = window.atob(res.data.Data.base64String)
            const bytes = new Uint8Array(binaryString.length)
            const mappedData = bytes.map((byte, i) => binaryString.charCodeAt(i))
            const blob = new Blob([mappedData], { type: res.data.Data.file.mimetype })
            const fileURL = window.URL.createObjectURL(blob)
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', res.data.Data.file.name)
            document.body.appendChild(fileLink)
            fileLink.click()
            URL.revokeObjectURL(fileURL)
          } else {
            Vue.prototype.$toast({
              component: ToastificationContent,
              props: {
                title: 'File danneggiato. Ripristinare il file nel pannello di configurazione',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    }

    const uploadImages = () => {
      const formData = new FormData()
      const data = {}
      data.files = newfiles.value
      for (let i = 0; i < newfiles.value.length; i += 1) {
        formData.append('files', newfiles.value[i])
      }
      formData.append('objtype', props.linkedObject)
      formData.append('objid', props.linkedId)
      formData.append('userid', userData.id)
      Vue.prototype.$http.post('/base/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          // Gestisci la risposta dal server
          console.log(response.data)
          if (response.data.ResultCode < 0) {
            Vue.prototype.$toast({
              component: ToastificationContent,
              props: {
                title: response.data.ResultMessage,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          } else {
            newfiles.value = []
          }
        })
        .catch(error => {
          // Gestisci l'errore
          console.error(error)
        })
        .finally(() => {
          fetchFiles()
        })
    }

    watch(props, () => {
      fetchFiles()
    })
    fetchFiles()
    return {
      userData,
      files,
      newfiles,
      fetchFiles,
      show,
      uploadImages,
      DeleteFile,
      DownloadFile,
    }
  },
  methods: {
    formatDateTime(formatedDate) {
      if (formatedDate) {
        const date = new Date(formatedDate) // formated_Date - SDK returned date

        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      } return ""
    },
  },
}


</script>
