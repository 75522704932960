<template>
  <section id="gestione-prodotto">
    <!-- Table Container Card -->
    <b-card
      title="Prodotto Assicurativo"
    >
      <b-overlay
        :show="showOverlay"
        rounded="sm"
      >
        <validation-observer
          #default="{ handleSubmit }"
          ref="ProductRules"
        >
          <b-form @submit.prevent="handleSubmit(saveProduct())">
            <b-row>
              <b-col md="5">
                <b-form-group
                  label="Nome"
                  label-for="Name"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Nome"
                  >
                    <b-form-input
                      id="Name"
                      v-model="ObjectToEdit.Name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Nome Prodotto Assicurativo"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group
                  label="Compagnia Associata"
                  label-for="company"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Compagnia"
                  >
                    <v-select
                      v-model="ObjectToEdit.company"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :clearable="true"
                      :options="companyList"
                      label="company"
                      index="Id"
                      :reduce="val => val"
                      class="fixborder"
                      input-id="company"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  label="Abilitato"
                  label-for="is-enabled-product"
                >
                  <b-form-checkbox
                    v-model="ObjectToEdit.IsEnabled"
                    input-id="is-enabled-product"
                    switch
                    value="Y"
                    unchecked-value="N"
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
              <!-- submit and reset -->
              <b-col md="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  {{ (ObjectToEdit.Id)? "Salva" : "Aggiungi" }}
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="resetObject"
                >
                  Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-overlay>
    </b-card>
    <zona-list
      v-if="ObjectToEdit.Id"
      :product-id="ObjectToEdit.Id"
    />
    <attachments
      v-if="ObjectToEdit.Id"
      linked-object="Product"
      :linked-id.sync="ObjectToEdit.Id"
    />
  </section>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BCard,
  BOverlay,
  BFormTextarea,
  BFormCheckbox,
  BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import Ripple from 'vue-ripple-directive'
import {
  ref, watch, computed, onUnmounted,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { useRouter } from '@core/utils/utils'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import productStoreModule from './productStoreModule'
import Attachments from '../../base/Attachments.vue'
import companyStoreModule from '../Company/companyStoreModule'

import ZonaList from '../Zona/ZonaList.vue'

export default {
  components: {
    BCard,
    BOverlay,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BFormCheckbox,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    ZonaList,
    Attachments,
  },
  directives: {
    Ripple,
  },
  // mounted() {
  //   companyStoreModule.actions.fetchCompanies().then(res => {
  //     this.companyList = res.data.companies
  //   }).catch(err => { console.log(" Companies error"); console.log(err) })
  // },
  methods: {
    saveProduct() {
      this.$refs.ProductRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          store.dispatch('app-product/AddUpdateProduct', { product: this.ObjectToEdit, userData: this.userData })
            .then(response => {
              this.$swal({
                icon: 'success',
                title: 'Modifica effettuata!',
                text: 'Il prodotto è stato memorizzato.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              }).then(resp => {
                this.$router.push({ name: 'add-edit-product', params: { id: resp.data.Id } })
              })
            }).catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
  },
  setup() {
    const PRODUCT_DEALER_STORE_MODULE_NAME = 'app-product'
    const showOverlay = ref(true)
    const companyList = ref([])
    const emptyObject = {
      Id: null,
      Name: "",
      IsEnabled: "N",
      packages: [],
      company: null, // { Id: null },
    }
    const ObjectToEdit = ref({})
    const OriginalObject = ref({})

    // Register module
    if (!store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.registerModule(PRODUCT_DEALER_STORE_MODULE_NAME, productStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_DEALER_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_DEALER_STORE_MODULE_NAME)
    })
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)

    const fetchCompanies = () => {
      showOverlay.value = true
      companyStoreModule.actions.fetchCompanies().then(res => {
        companyList.value = res.data.companies
        showOverlay.value = false
      }).catch(err => {
        console.log(" Companies error")
        console.log(err)
        showOverlay.value = false
      })
    }

    const fetchProduct = Id => {
      showOverlay.value = true
      store.dispatch(`${PRODUCT_DEALER_STORE_MODULE_NAME}/fetchProduct`, {
        id: Id,
      })
        .then(response => {
          ObjectToEdit.value = response.data.product
          OriginalObject.value = JSON.parse(JSON.stringify(response.data.product))
          showOverlay.value = false
        }).catch(error => {
          ObjectToEdit.value = JSON.parse(JSON.stringify(emptyObject))
          OriginalObject.value = JSON.parse(JSON.stringify(emptyObject))
          showOverlay.value = false
        })
    }

    const resetObject = () => {
      ObjectToEdit.value = JSON.parse(JSON.stringify(OriginalObject.value))
    }

    watch(routeParams, () => {
      try {
        if (routeParams.value.id == 'new') {
          ObjectToEdit.value = JSON.parse(JSON.stringify(emptyObject))
        } else {
          fetchProduct(routeParams.value.id)
        }
      } catch (error) {
        ObjectToEdit.value = null
      }
    })

    try {
      if (routeParams.value.id == 'new') {
        ObjectToEdit.value = emptyObject
      } else {
        fetchProduct(routeParams.value.id)
      }
    } catch (error) {
      ObjectToEdit.value = null
    }

    const userData = getUserData()
    fetchCompanies()


    return {
      ObjectToEdit,
      OriginalObject,
      resetObject,
      showOverlay,
      companyList,
      fetchProduct,
      userData,
      route,
      router,
    }
  },
}
</script>

  <style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }

  .agency-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
  </style>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>

